@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Judson";
    font-weight: normal;
    font-style: normal;
    font-display: auto;
    src: url('./assets/fonts/Judson/Judson-Regular.ttf');
}

@font-face {
    font-family: "Judson";
    font-weight: bold;
    font-style: normal;
    font-display: auto;
    src: url('./assets/fonts/Judson/Judson-Bold.ttf');
}

body {
    font-family: 'Judson', 'Franklin Gothic Medium', Arial, sans-serif;
}